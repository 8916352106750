import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import moment from "moment"
import "moment/locale/pt"

import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share"

import SEO from "../components/seo"
import SimpleLayout from "../layouts/SimpleLayout"

import styles from "./styles/blog-post.module.css"

function readingTime(text) {
  const wordsPerMinute = 200
  const noOfWords = text.split(/\s/g).length
  const minutes = noOfWords / wordsPerMinute
  const readTime = Math.ceil(minutes)
  return `${readTime} minutos de leitura`
}

export const BlogPostPage = props => {
  const { author } = props
  const url = `https://cloudware.pt${props.data.markdownRemark.fields.slug}`
  const seoDescription = (props.description || "").slice(0, 500)

  const [showSocialButtons, setShowSocialButtons] = useState()

  return (
    <SimpleLayout path={props.path}>
      <SEO
        title={props.title}
        description={`${
          props.subtitle ? props.subtitle : ""
        } \n${seoDescription}... \n Artigo escrito por ${
          author?.frontmatter?.name
        }, ${author?.frontmatter?.title}.`}
        url={url}
        image={
          props.featuredImage &&
          props.featuredImage.childImageSharp &&
          props.featuredImage.childImageSharp.fluid.src
        }
      />
      <header className={styles.blogHeader}>
        <img
          alt=""
          className={styles.blogHeaderImg}
          src={props.headerImage.fixed.src}
        />
      </header>

      <section className={`${styles.blog} ${styles.blogTop}`}>
        <div className={styles.content}>
          <Link to="/blog" className={styles.goBack}>
            {"<   Voltar"}
          </Link>
          <div className={styles.blogPost}>
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${props.featuredImage &&
                  props.featuredImage.childImageSharp.fluid.src})`,
              }}
            >
              <div className={styles.overlay}></div>
              <h2 className={styles.postTitle}>{props.title}</h2>
              {props.subtitle && props.subtitle !== " " && (
                <h3 className={styles.subtitle}>{props.subtitle}</h3>
              )}
              <p className={styles.titleDate}>
                {moment(props.date)
                  .locale("pt")
                  .format("DD MMMM")}
              </p>
            </div>
            <div className={styles.postContent}>
              <div className={styles.author}>
                <div
                  className={styles.profilePicture}
                  style={{
                    backgroundImage: `url(${author?.frontmatter?.profilePicture &&
                      author?.frontmatter?.profilePicture.childImageSharp.fluid
                        .src})`,
                  }}
                />
                <div className={styles.authorInfo}>
                  <span className={styles.authorName}>
                    <strong>{author?.frontmatter?.name}</strong>
                    <span className={styles.separator} />
                    {author?.frontmatter?.title}
                  </span>
                  <span className={styles.date}>
                    {moment(props.date)
                      .locale("pt")
                      .format("D MMMM YYYY")}{" "}
                    <span className={styles.separator} />
                    {readingTime(props.text)}
                  </span>
                </div>
              </div>
              <div className={styles.share}>
                {/* eslint-disable-next-line */}
                <div
                  className={styles.shareButton}
                  onClick={() => {
                    setShowSocialButtons(!showSocialButtons)
                  }}
                >
                  <img
                    className={styles.shareIcon}
                    alt="share icon"
                    src={require("../img/share.png")}
                  />
                </div>
                <div
                  className={`${styles.socialButtons} ${
                    !showSocialButtons ? styles.hide : {}
                  }`}
                >
                  <FacebookShareButton
                    url={url}
                    className={`${styles.socialShareButton} ${
                      styles.facebook
                    } ${showSocialButtons ? styles.active : {}}`}
                  >
                    <img
                      className={styles.shareIcon}
                      alt="facebook share icon"
                      src={require("../img/facebook-logo.png")}
                    />
                  </FacebookShareButton>

                  <EmailShareButton
                    url={url}
                    className={`${styles.socialShareButton} ${styles.email} ${
                      showSocialButtons ? styles.active : {}
                    }`}
                  >
                    <img
                      className={styles.shareIcon}
                      alt="email share icon"
                      src={require("../img/black-back-closed-envelope-shape.png")}
                    />
                  </EmailShareButton>

                  <LinkedinShareButton
                    url={url}
                    className={`${styles.socialShareButton} ${
                      styles.linkedin
                    } ${showSocialButtons ? styles.active : {}}`}
                  >
                    <img
                      className={styles.shareIcon}
                      alt="linkedin share icon"
                      src={require("../img/linkedin-logo.png")}
                    />
                  </LinkedinShareButton>
                </div>
              </div>
              <ReactMarkdown className={styles.text} source={props.text} />
            </div>
          </div>
        </div>
      </section>
    </SimpleLayout>
  )
}

const BlogPost = props => {
  const { data } = props
  if (!data) {
    return <div></div>
  }
  const { frontmatter: blogPost } = data.markdownRemark

  return (
    <BlogPostPage
      {...props}
      headerImage={data.image}
      title={blogPost.title}
      subtitle={blogPost.subtitle}
      text={blogPost.text}
      author={blogPost.author}
      category={blogPost.category}
      date={blogPost.date}
      featuredImage={blogPost.image}
    />
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    image: imageSharp(original: { src: { regex: "/.*blog-header.*/" } }) {
      fixed(width: 1600, quality: 92) {
        ...GatsbyImageSharpFixed
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      frontmatter {
        templateKey
        title
        subtitle
        text
        date
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 92) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author {
          frontmatter {
            author_id
            name
            title
            profilePicture {
              childImageSharp {
                fluid(maxWidth: 100, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        category
      }
    }
  }
`
